import React       from 'react';
import { graphql } from 'gatsby';

import Lightbox    from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import Wrapper     from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading     from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import SEO         from '@interness/web-core/src/components/modules/SEO/SEO';

import Subnav    from '../components/Subnavigation';
import HeaderImg from '../components/HeaderImg';

const ServicePageTemplate = (props) => {
  const { data, gallery } = props.data;
  return (
    <>
      <SEO title={data.heading}/>
      <HeaderImage>
        <HeaderImg id={data.headerId}/>
      </HeaderImage>
      <Subnav/>
      <Wrapper>
        <Spacer/>
        <Heading subtitle={'Pierenkemper Tischlerei und Möbelwerkstätten'}>{data.heading}</Heading>
        <p>{data.text}</p>
        <Spacer/>
        <Lightbox images={gallery.images} thumbnails={gallery.thumbnails}/>
        <Spacer/>
      </Wrapper>
    </>
  )
};

export default ServicePageTemplate;

export const query = graphql`
  query($slug: String!, $galleryId: String!) {
    data: pagesYaml(slug: {eq: $slug}) {
      displayName
      slug
      text
      galleryId
      headerId
      heading
    }
    gallery: directusMediaCollection(name: {eq: $galleryId}) {
      ...LightboxGalleryGrid
    }
  }
`;