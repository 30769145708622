import React                       from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Subnavigation from '@interness/web-core/src/components/structure/Subnavigation/Subnavigation';

const generateMenuStructure = (data) => {
  const menu = [];
  data.forEach(page => {
    menu.push({
      displayName: page.displayName,
      path: `/leistungen/${page.slug}`,
    })
  });
  return menu;
};

const Subnav = () => {
  const { menu } = useStaticQuery(query);
  return <Subnavigation menu={generateMenuStructure(menu.nodes)} menuTitle={'Leistungen'}
                        overview={{ displayName: 'Übersicht', path: '/leistungen' }}/>
};

export default Subnav;

const query = graphql`
  query {
    menu: allPagesYaml {
      nodes {
        displayName
        slug
      }
    }
  }
`;